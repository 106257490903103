import React from "react";
import PropTypes from "prop-types";

const Arrow = ({ className, color, direction }) => {
  if (direction === `<`) {
    className = `${className} invert-x`;
  }

  return (
    <svg className={className} viewBox="0 0 12 12" fill="none">
      <path
        d="M11.9817 6C11.9817 5.56467 11.9817 5.14826 12 4.84543C10.758 5.01577 4.74886 5.29968 1.99087 5.41325L1.9726 5.26183C3.94521 4.4858 6.9589 2.95268 8.3105 1.57098C7.96347 1.15457 7.45206 0.378549 7.23288 8.62512e-08C5.62557 1.96845 1.75342 4.4101 8.26089e-08 5.07256C0.0182647 5.29968 0.0182647 5.65931 0.0182647 6C0.0182647 6.34069 0.0182647 6.70032 6.04896e-08 6.92744C1.75342 7.58991 5.62557 10.0315 7.23288 12C7.45205 11.6215 7.96347 10.8454 8.3105 10.429C6.9589 9.04732 3.94521 7.5142 1.9726 6.73817L1.99087 6.58675C4.74886 6.70032 10.758 6.98423 12 7.15457C11.9817 6.85174 11.9817 6.43533 11.9817 6Z"
        fill={color}
      />
    </svg>
  );
};

Arrow.defaultProps = {
  className: ``,
  color: `black`,
  direction: `>`
};

Arrow.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  direction: PropTypes.string
};

export default Arrow;
