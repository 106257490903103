import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Glide from "@glidejs/glide";
import { DocumentContext } from "~context/DocumentContext";
import Arrow from "~components/svg/Arrow";

const Carousel = ({
  bulletColor,
  className,
  items,
  keyPrefix,
  onUpdate,
  withArrows,
  withBullets
}) => {
  const { windowWidth } = useContext(DocumentContext);

  const glideRef = useRef();

  const [activeIndex, setActiveIndex] = useState(0);
  const [glide, setGlide] = useState(null);

  //

  const carouselNext = () => {
    if (!glide) {
      return;
    }

    glide.go(`<`);
  };

  const carouselPrev = () => {
    if (!glide) {
      return;
    }

    glide.go(`>`);
  };

  // useEffect(() => {
  // TODO : stop memory leak
  // }, []);

  useEffect(() => {
    if (!glideRef?.current || !windowWidth || glide) {
      return;
    }

    const glideCarousel = new Glide(`#${keyPrefix}-glide`, {
      autoplay: 3000,
      gap: 0,
      hoverpause: true,
      perView: 1,
      type: `carousel`
    });

    glideCarousel.on(`run`, () => {
      setActiveIndex(glideCarousel.index);

      onUpdate(glideCarousel.index);
    });

    glideCarousel.mount();

    setGlide(glideCarousel);
  }, [glideRef, windowWidth]);

  //

  return (
    <section
      key={`${keyPrefix}-container`}
      className={`carousel ${className ||
        `w-full relative`} flex items-center justify-center overflow-x-hidden`}
    >
      {withArrows && (
        <button
          type="button"
          className="carousel__arrow carousel__arrow--left relative pt-v1 pb-v1 pl-v1 flex items-center justify-start"
          onClick={carouselNext}
        >
          <Arrow className="relative block" color="no-milk" direction="<" />
        </button>
      )}

      <div className="carousel__glide w-full relative block">
        <div id={`${keyPrefix}-glide`} ref={glideRef} className="glide">
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides">
              {items.map((itemJSX, itemIndex) => {
                const key = `${keyPrefix}-item-${itemIndex}`;

                return (
                  <li key={key} className="glide__slide relative">
                    {itemJSX}
                  </li>
                );
              })}
            </ul>
          </div>

          {withBullets && (
            <div
              className="glide__bullets relative xs:mt-6 flex items-center justify-center"
              data-glide-el="controls[nav]"
            >
              {items.map((_, itemIndex) => {
                const bulletKey = `${keyPrefix}-bullet-${itemIndex}`;

                return (
                  <button
                    key={bulletKey}
                    type="button"
                    className={`glide__bullet ${
                      activeIndex === itemIndex ? `` : `opacity-50`
                    } bg-${bulletColor}`}
                    data-glide-dir={`=${itemIndex}`}
                  ></button>
                );
              })}
            </div>
          )}
        </div>
      </div>

      {withArrows && (
        <button
          type="button"
          className="carousel__arrow carousel__arrow--right relative pt-v1 pr-v1 pb-v1 flex items-center justify-end"
          onClick={carouselPrev}
        >
          <Arrow className="relative block" color="no-milk" direction=">" />
        </button>
      )}
    </section>
  );
};

Carousel.defaultProps = {
  bulletColor: `white`,
  className: ``,
  keyPrefix: `glide-carousel`,
  onUpdate: () => {},
  withArrows: false,
  withBullets: false
};

Carousel.propTypes = {
  bulletColor: PropTypes.string,
  className: PropTypes.string,
  items: PropTypes.node.isRequired,
  keyPrefix: PropTypes.string,
  onUpdate: PropTypes.func,
  withArrows: PropTypes.bool,
  withBullets: PropTypes.bool
};

export default Carousel;
