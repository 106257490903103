import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

const ProductOverview = ({
  image,
  imageAlt,
  heading,
  subheading,
  text,
  position,
  backgroundColor
}) => {
  let color = `white`;

  switch (backgroundColor) {
    case `hit-pink`:
      color = `black`;
      break;

    default:
      color = `white`;
      break;
  }

  //

  return (
    <section
      className={`w-full relative py-v3 xs:py-10 ${
        backgroundColor ? `bg-${backgroundColor}` : ``
      }`}
      style={{
        backgroundColor,
        color
      }}
    >
      <article
        className={`grid xs:flex ${
          position === `left` ? `xs:flex-col-reverse` : `xs:flex-col`
        }`}
      >
        {position === `left` ? (
          <>
            <figure className="grid-end-6 h-full rounded-edge flex items-center justify-center overflow-hidden xs:mt-5 ">
              <Img
                className="w-full h-full relative block object-cover"
                fluid={image}
                alt={imageAlt}
              />
            </figure>

            <div className="grid-end-6 h-full flex flex-col items-center justify-center text-center">
              {subheading && (
                <h4 className="caption uppercase">{subheading}</h4>
              )}
              <h2 className="mt-v1 f1 uppercase xs:mt-3">{heading}</h2>

              <p className="w-5/6 mt-v3 b2 xs:w-full">{text}</p>
            </div>
          </>
        ) : (
          <>
            <div className="grid-end-6 h-full flex flex-col items-center justify-center text-center">
              {subheading && (
                <h4 className="caption uppercase">{subheading}</h4>
              )}
              <h2 className="mt-v1 f1 uppercase xs:mt-3">{heading}</h2>

              <p className="w-5/6 mt-v3 b2 xs:w-full">{text}</p>
            </div>

            <figure className="grid-end-6 h-full rounded-edge flex items-center justify-center overflow-hidden xs:mt-5">
              <Img
                className="w-full h-full relative block object-cover"
                fluid={image}
                alt={imageAlt}
              />
            </figure>
          </>
        )}
      </article>
    </section>
  );
};

ProductOverview.defaultProps = {
  image: null,
  imageAlt: null,
  heading: null,
  subheading: null,
  text: null,
  position: `right`,
  backgroundColor: `mineral`
};

ProductOverview.propTypes = {
  image: PropTypes.shape({}),
  imageAlt: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  text: PropTypes.string,
  position: PropTypes.string,
  backgroundColor: PropTypes.string
};

export default ProductOverview;
